import config from '@/config';

export default function parseRealEstateItems(item) {
  return {
    id: item.Id,
    lat: item.Latitude ?? null,
    lng: item.Longitude ?? null,
    objectId: item.IdentificationNumber ?? null,
    isSalesEstate: item.IsSalesEstate,
    isRentalEstate: item.IsRentalEstate,
    images: item.EstateMedia.map((image) => ({
      src: `${config.backend}/../..${image.ImageUrls.m}`,
      alt: image.Description ?? null,
      type: image.Type,
    })),
    imagesL: item.EstateMedia.map((image) => ({
      src: `${config.backend}/../..${image.ImageUrls.l}`,
      alt: image.Description ?? null,
      type: image.Type,
    })),
    title: item.EstateTitle ?? null,
    description: item.EstateDescription ?? null,
    location: item.Location ?? null,
    town: item.Town ?? null,
    purchasePrice: item.PurchasePrice ?? null,
    deactivated: false,
    deleted: item.Deleted ?? null,
    isFavorite: item.isFavourite ?? null,
    keyData: {
      area: item.TotalArea ?? null,
      roomCount: item.RoomNumber ?? null,
      livingSpace: item.LivingSpace ?? null,
      realtyArea: item.RealtyArea ?? null,
      street: item.Street ?? null,
      houseNumber: item.HouseNumber ?? null,
      zip: item.Zip ?? null,
      town: item.Town ?? null,
      estateType: item.EstateType ?? null,
    },
    energy: {
      certificate: {
        type: item.EnergyCertificatePrimaryEnergyCarrier ?? null,
        energyDemand: item.EnergyCertificateEnergyDemand ?? null,
        valueClass: item.EnergyCertificateValueClass ?? null,
        issueDate: item.EnergyCertificateIssueDate ?? null,
        valueUntil: item.EnergyCertificateValueUntil ?? null,
        constructionYear: item.EnergyCertificateConstructionYear ?? null,
        energyCertificateResidentialBuildingType: item.EnergyCertificateResidentialBuildingType ?? null,
        energyCertificateType: item.EnergyCertificateType ?? null,
      },
      estimatedElectricityCosts: item.estimatedElectricityCosts ?? null,
      estimatedHeatingCosts: item.estimatedHeatingCosts ?? null,
      text: item.EnergyCertificateText ?? null,
    },
    contact: {
      salutation: item.ContactPerson?.Greeting ?? null,
      firstName: item.ContactPerson?.FirstName ?? null,
      lastName: item.ContactPerson?.LastName ?? null,
      company: item.ContactPerson?.Company ?? null,
      centralEmail: item.ContactPerson?.CentralEmail ?? null,
      email: item.ContactPerson?.Email ?? null,
      phone: item.ContactPerson?.Telephone ?? null,
      street: item.ContactPerson?.Street ?? null,
      houseNumber: item.ContactPerson?.HouseNumber ?? null,
      zip: item.ContactPerson?.Zip ?? null,
      town: item.ContactPerson?.Town ?? null,
      imagesSrc: item.ContactPerson?.ImageUrl ? `${config.backend}/../..${item.ContactPerson?.ImageUrl}` : null,
      url: item.ContactPerson?.Url ?? null,
    },
    objectData: {
      estate: {
        estateType: item.EstateType ?? null,
        constructionType: item.ConstructionType ?? null,
        constructionYear: item.ConstructionYear ?? null,
        development: item.Development ?? null,
        condition: item.Condition ?? null,
        lastModernizationYear: item.LastModernizationYear ?? null,
        availabilityDate: item.AvailabilityDate ?? null,
        livingSpace: item.LivingSpace ?? null,
        usableArea: item.UsableArea ?? null,
        realtyArea: item.RealtyArea ?? null,
        tenancyArea: item.TenancyArea ?? null,
        livingUnitNumber: item.LivingUnitNumber ?? null,
        commericalUnitNumber: item.CommericalUnitNumber ?? null,
        roomNumber: item.RoomNumber ?? null,
        bedRoomNumber: item.BedRoomNumber ?? null,
        bathRooms: item.BathRooms ?? null,
        separateToilettNumber: item.SeparateToilettNumber ?? null,
        balconyNumber: item.BalconyNumber ?? null,
        terraceNumber: item.TerraceNumber ?? null,
        parkingSpaceType: item.ParkingSpaceType ?? null,
        parkingSpaceNumber: item.ParkingSpaceNumber ?? null,
        garageNumber: item.GarageNumber ?? null,
      },
    },
    feature: item.FeatureList,
    featureText: item.Features ?? null,
    furnishing: {
      fittedKitchen: item.FittedKitchen ?? null,
      balcony: item.Balcony ?? null,
      garden: item.Garden ?? null,
      storeroom: item.Storeroom ?? null,
      windowedBathroom: item.WindowedBathroom ?? null,
      bathub: item.Bathub ?? null,
      disabilityAccess: item.DisabilityAccess ?? null,
      bicycleRoom: item.BicycleRoom ?? null,
      passengerLift: item.PassengerLift ?? null,
      goodsLift: item.GoodsLift ?? null,
      guestToilet: item.GuestToilet ?? null,
      fireplace: item.Fireplace ?? null,
      airConditioned: item.AirConditioned ?? null,
      furnished: item.Furnished ?? null,
      wheelchairAccessible: item.WheelchairAccessible ?? null,
      sauna: item.Sauna ?? null,
      seniorFocused: item.SeniorFocused ?? null,
      swimmingPool: item.SwimmingPool ?? null,
      laundryOrDryingRoom: item.LaundryOrDryingRoom ?? null,
      flatShareSuited: item.FlatShareSuited ?? null,
      winterGarden: item.WinterGarden ?? null,
      shower: item.Shower ?? null,
      floorHeating: item.FloorHeating ?? null,
      cableOrSateliteTv: item.CableOrSateliteTv ?? null,
      penthouse: item.Penthouse ?? null,
      monumentalProtection: item.MonumentalProtection ?? null,
      firstUse: item.FirstUse ?? null,
      commercialUseAllowed: item.CommercialUseAllowed ?? null,
      petsAllowed: item.PetsAllowed ?? null,
      basement: item.Basement ?? null,
      loggia: item.Loggia ?? null,
      nonSmoker: item.NonSmoker ?? null,
      terrace: item.Terrace ?? null,
      secondBathRoom: item.SecondBathRoom ?? null,
    },
    rentalEstate: {
      rentWarm: item.RentWarm ?? null,
      rentCold: item.RentCold ?? null,
      subsidaryCost: item.SubsidaryCost ?? null,
      heatingCosts: item.HeatingCosts ?? null,
      deposit: item.Deposit ?? null,
      externalCourtage: item.ExternalCourtage ?? null,
      rentpriceOnRequest: item.RentPriceOnRequest ?? null,
    },
    purchaseEstate: {
      purchasePriceOnRequest: item.PurchasePriceOnRequest ?? null,
    },
    calcExampleRate: {
      boundInterestRate: item?.calcExampleRate?.boundInterestRate ?? null,
      effectiveInterestRate: item?.calcExampleRate?.effectiveInterestRate ?? null,
      equityCapital: item?.calcExampleRate?.equityCapital ?? null,
      equityCapitalRatio: item?.calcExampleRate?.equityCapitalRatio ?? null,
      fixedInterestRate: item?.calcExampleRate?.fixedInterestRate ?? null,
      incidentalPurchaseCosts: item?.calcExampleRate?.incidentalPurchaseCosts ?? null,
      incidentalPurchaseCostsRatio: item?.calcExampleRate?.incidentalPurchaseCostsRatio ?? null,
      initialRepayment: item?.calcExampleRate?.initialRepayment ?? null,
      loanAmount: item?.calcExampleRate?.loanAmount ?? null,
      monthlyRate: item?.calcExampleRate?.monthlyRate ?? null,
      konditionen: {
        zinsBindungInJahren: item?.calcExampleRate?.konditionen?.zinsBindungInJahren ?? null,
        sollZins: item?.calcExampleRate?.konditionen?.sollZins ?? null,
        produktAnbieter: item?.calcExampleRate?.konditionen?.produktAnbieter ?? null,
        summeZahlungen: item?.calcExampleRate?.konditionen?.summeZahlungen ?? null,
        laufzeitInMonaten: item?.calcExampleRate?.konditionen?.laufzeitInMonaten ?? null,
        effektivZins: item?.calcExampleRate?.konditionen?.effektivZins ?? null,
      },
    },
  };
}
