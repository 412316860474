import { NextSeo } from 'next-seo';
import useTranslation from 'next-translate/useTranslation';
import PropTypes from 'prop-types';

const Seo = ({ page }) => {
  const { t } = useTranslation('seo-meta-text');
  const { t: h } = useTranslation(page);
  const ogImage = h('NextSeo.openGraphImage');

  const SeoTemplate = {
    title: h('NextSeo.metaTitle') ?? null,
    description: h('NextSeo.metaDescription') ?? null,
    canonical: h('NextSeo.metaCanonical') ?? null,
    twitter: {
      site: t('twitterSite') ?? null,
      cardType: 'summary_large_image',
    },
    nofollow: h('NextSeo.robotsNofollow', {}, { returnObjects: true }),
    noindex: h('NextSeo.robotsNoindex', {}, { returnObjects: true }),
    robotsProps: {
      nosnippet: h('NextSeo.robotsNosnippet', {}, { returnObjects: true }),
      noimageindex: h('NextSeo.robotsNoimageindex', {}, { returnObjects: true }),
      noarchive: h('NextSeo.robotsNoarchive', {}, { returnObjects: true }),
    },
    openGraph: {
      type: 'website',
      title: h('NextSeo.openGraphTitle') ?? null,
      description: h('NextSeo.openGraphDescription') ?? null,
      site_name: t('openGraphSitename') ?? null,
      ...(ogImage === 'page-expose:NextSeo.openGraphImage'
        ? null
        : {
            images: [
              {
                alt: h('NextSeo.openGraphImage.alternativeText') ?? null,
                url: h('NextSeo.openGraphImage.url') ?? null,
              },
            ],
          }),
    },
    additionalLinkTags: [
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: t('appleTouchIcon.url'),
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: t('favIconLarge.url'),
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: t('favIconSmall.url'),
      },
      {
        rel: 'manifest',
        href: t('manifestUrl'),
      },
      {
        rel: 'shortcut icon',
        href: t('shortcutIcon.url'),
      },
    ],
    additionalMetaTags: [
      {
        name: 'msapplication-TileColor',
        content: '#da532c',
      },
      {
        name: 'msapplication-config',
        content: t('msapplicationConfigUrl'),
      },
      {
        name: 'theme-color',
        content: '#ffffff',
      },
    ],
  };
  return <NextSeo {...SeoTemplate} />;
};

Seo.propTypes = {
  page: PropTypes.string.isRequired,
};

export default Seo;
